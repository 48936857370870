import React from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import Slide from "@material-ui/core/Slide";
import logo from "../assets/logo.svg";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as ROUTES from "../constants/routes";
import Fade from "@material-ui/core/Fade";

import { history } from "./history.js";

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      "&:hover": {
        opacity: 0.8,
      },
    },
    scrollLogo: {
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        visibility: "hidden",
      },
      [theme.breakpoints.up("md")]: {
        left: 0,
      },
      [theme.breakpoints.up("lg")]: {
        left: 0,
      },
      [theme.breakpoints.up("xl")]: {
        left: "6.5%",
      },
    },
  })
);

export default function ScrollLogo() {
  const [shouldShowActions, setShouldShowActions] = React.useState(false);
  const [shouldShowOpening, setShouldShowOpening] = React.useState(false);
  const [shouldShowVisible, setShouldShowVisible] = React.useState(false);

  const classes = useStyles();

  React.useEffect(() => {
    console.log(checkIfOpen());
    function handleScroll() {
      const yPos = window.scrollY;
      const showImage = yPos > 180;
      const showOpening = yPos > 700;
      const showVisible = yPos > 710;

      setShouldShowActions(showImage);
      setShouldShowOpening(showOpening);
      setShouldShowVisible(showVisible);
    }

    window.addEventListener("scroll", handleScroll, false);

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [shouldShowActions]);

  const checkIfOpen = () => {
    var startTimeMorning = "08:00:00";
    var endTimeMorning = "12:00:00";

    var startTimeEvening = "14:00:00";
    var endTimeEvening = "18:00:00";

    var currentDate = new Date();

    var isWednesdayAfternoon =
      currentDate.getDay() === 3 &&
      currentDate.getHours() >= 14 &&
      currentDate.getHours() < 18;

    if (
      currentDate.getDay() === 6 ||
      currentDate.getDay() === 0 ||
      isWednesdayAfternoon
    ) {
      return false;
    }

    var startDateMorning = new Date(currentDate.getTime());
    startDateMorning.setHours(startTimeMorning.split(":")[0]);
    startDateMorning.setMinutes(startTimeMorning.split(":")[1]);
    startDateMorning.setSeconds(startTimeMorning.split(":")[2]);

    var endDateMorning = new Date(currentDate.getTime());
    endDateMorning.setHours(endTimeMorning.split(":")[0]);
    endDateMorning.setMinutes(endTimeMorning.split(":")[1]);
    endDateMorning.setSeconds(endTimeMorning.split(":")[2]);

    var startDateEvening = new Date(currentDate.getTime());
    startDateEvening.setHours(startTimeEvening.split(":")[0]);
    startDateEvening.setMinutes(startTimeEvening.split(":")[1]);
    startDateEvening.setSeconds(startTimeEvening.split(":")[2]);

    var endDateEvening = new Date(currentDate.getTime());
    endDateEvening.setHours(endTimeEvening.split(":")[0]);
    endDateEvening.setMinutes(endTimeEvening.split(":")[1]);
    endDateEvening.setSeconds(endTimeEvening.split(":")[2]);

    var valid =
      (startDateMorning < currentDate && endDateMorning > currentDate) ||
      (startDateEvening < currentDate && endDateEvening > currentDate);

    return valid;
  };

  if (shouldShowActions) {
    return (
      <Slide in={shouldShowActions}>
        <div className={classes.scrollLogo}>
          <div
            style={{
              display: "block",
              boxShadow:
                "0 4px 2px -2px rgba(0, 0, 0, 0.3), 0 4px 4px 0px rgba(0, 0, 0, 0.1)",
            }}
          >
            <ButtonBase onClick={() => history.push(ROUTES.HOME)}>
              <div
                style={{
                  position: "relative",
                  zIndex: 200,
                  backgroundColor: "#ffffff",
                  height: 150,
                  width: 150,
                  padding: 10,
                }}
              >
                <img
                  className={classes.logo}
                  alt="logo"
                  src={logo}
                  style={{ height: 120, margin: "auto" }}
                />
              </div>
            </ButtonBase>

            {shouldShowVisible && (
              <Fade in={shouldShowOpening} timeout={1000}>
                <div
                  style={{
                    display: "flex",
                    width: "150px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <div style={{ margin: "auto auto 0px auto", padding: 8 }}>
                    <span
                      style={{
                        color: "#13294b",
                        fontWeight: "bold",
                        fontSize: "1.1rem",
                        textAlign: "left",
                      }}
                    >
                      Öffnungszeiten{" "}
                    </span>
                    <div
                      style={{
                        textAlign: "left",
                        fontSize: "1rem",
                        marginTop: 10,
                      }}
                    >
                      {" "}
                      Montag - Freitag
                      <br />
                      08.00 - 12.00 Uhr
                      <br />
                      14.00 - 18.00 Uhr
                      <br />
                      <span
                        style={{
                          textAlign: "center",
                          display: "flex",
                          marginTop: 12,
                        }}
                      >
                        {" "}
                        Mittwoch Nachmittag geschlossen
                      </span>
                      <br />
                      {checkIfOpen() ? (
                        <div
                          style={{
                            fontWeight: "bold",
                            color: "green",
                            fontSize: "1.2rem",
                            textAlign: "center",
                          }}
                        >
                          Geöffnet
                        </div>
                      ) : (
                        <div
                          style={{
                            fontWeight: "bold",
                            color: "red",
                            fontSize: "1.2rem",
                            textAlign: "center",
                          }}
                        >
                          Geschlossen
                        </div>
                      )}
                      <ButtonBase
                        href="tel:060242220"
                        style={{
                          marginTop: 10,
                          fontSize: "1rem",
                          color: "#13294b",
                          width: "100%",
                        }}
                      >
                        Tel.:{" "}
                        <span style={{ fontWeight: "bold" }}>
                          &nbsp;06024/2220
                        </span>
                      </ButtonBase>
                    </div>
                  </div>
                </div>
              </Fade>
            )}
          </div>
        </div>
      </Slide>
    );
  } else {
    return null;
  }
}
