import {
  CHECK_SLIDERPAGE
} from '../constants/ActionTypes'

const initialState = {
  show: true,
  }

 const sliderPage = (state = initialState, action) => {

  switch (action.type) {
    case CHECK_SLIDERPAGE:
    return {
      ...state,
      show: false,

  }
    default:
      return state
  }
}




export const checkSliderpage = state => state.sliderPage

export default sliderPage;
