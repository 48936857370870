import React from 'react';
import slider1 from '../../../assets/slider1.jpg';
import slider2 from '../../../assets/slider2.jpg';
import slider3 from '../../../assets/slider3.jpg';
import slider4 from '../../../assets/slider4.jpg';
import slider5 from '../../../assets/slider5.jpg';

import Fab from '@material-ui/core/Fab';
import ArrowForward from '@material-ui/icons/ChevronRight';
import ArrowBack from '@material-ui/icons/ChevronLeft';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowDown from '@material-ui/icons/ExpandMore';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


const styles = theme => ({


  button: {
backgroundColor: 'rgba(0, 0, 0, 0.2)',
color: '#ffffff',
width: 50,
height: '100%',
 borderRadius: '0px',
 borderWidth: 0,
 outline: 'none',
 padding: 0
  }


});



 class CustomCarousel extends React.Component {
    images = [slider1, slider2, slider3, slider4, slider5]

   constructor(props) {
        super(props);

        this.state = {

    currentIndex: 0,
  }


}




     galleryItems = () => {
        return this.images.map((photo, i) => <img src={photo} key={i} style={{width: '100%', height: this.props.height - 230, objectFit: 'cover'}}/>)
      }




       slideNext = () => {
           this.setState((state) => ({
               currentIndex: state.currentIndex + 1,
           }));
       };

       slidePrev = () => {
           this.setState((state) => ({
               currentIndex: state.currentIndex - 1,
           }));
       };


       updateCurrentSlide = (index) => {
          const { currentIndex } = this.state;

          if (currentIndex !== index) {
              this.setState({
                  currentIndex: index,
              });
          }
      };

  render() {
    const { classes, theme } = this.props;
    const { galleryItems, responsive, currentIndex } = this.state

    return (
      <div style={{position: 'relative',  marginBottom: 30}}>
      <Carousel
      showThumbs={false}
      showIndicators={false}
      showStatus={false}
      showArrows={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={4000}
      transitionTime={1500}
      selectedItem={currentIndex}
      onChange={this.updateCurrentSlide}
      >
     {this.galleryItems()}
</Carousel>


                 <div style={{position: 'absolute', left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', bottom: 20, zIndex: 1}}>
                 {/*<div style={{fontWeight: 'bold', width: 'fit-content', color: '#ffffff', fontSize: "2rem",   backgroundColor: 'rgba(0, 0, 0, 0.4)', padding: 20, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', marginBottom: 30}}>
                 Ihre Kompetente Zahnberatung
                 <br/>
                  in Krombach.
                 </div> */}
                 <div style={{textAlign: 'center'}}>
                 <IconButton style={{borderRadius: '30px', backgroundColor: 'rgb(142, 66, 50)', padding: 10, color: '#ffffff'}} onClick={() => window.scrollTo({top: window.innerHeight - 80, left: 0, behavior: 'smooth'})}>
                 <ArrowDown style={{height: 40, margin: 'auto', width: 40}}/>
                 </IconButton>
                 </div>
                 </div>
      <div style={{ justifyContent: 'space-between', width: '100%', height: '100%', display: 'flex', position: 'absolute', top: 0, bottom: 0, marginTop: 'auto', marginBottom: 'auto'}}>
      <IconButton className={classes.button} onClick={() => this.slidePrev()} >
      <ArrowBack  style={{height: 40, margin: 'auto', width: 40}}/>

      </IconButton>
           <IconButton className={classes.button} onClick={() => this.slideNext()} >
           <ArrowForward style={{height: 40, margin: 'auto', width: 40}}/>

           </IconButton>
           </div>

      </div>
    );
  }
}

export default (withStyles(styles, { withTheme: true })(CustomCarousel))
