import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import "../../../App.css";
import { motion } from "framer-motion";

import azubis from "../../../assets/azubis.jpg";
import techniker from "../../../assets/techniker_leistung.jpg";
import dental from "../../../assets/dental.jpg";
import verwaltung from "../../../assets/verwaltung.jpg";
import zahnaerzte from "../../../assets/slider1_ipad.jpg";

import Divider from "@material-ui/core/Divider";

import { styles } from "../../../breakpoints";
import { Helmet } from "react-helmet";

import prophylaxe1 from "../../../assets/prophylaxe/prophylaxe_team_1.jpg";
import prophylaxe2 from "../../../assets/prophylaxe/prophylaxe_team_2.jpg";
import prophylaxe3 from "../../../assets/prophylaxe/prophylaxe_team_3.jpg";

import assistentinnen1 from "../../../assets/assistentinnen/assistentinnen_team_1.jpg";
import assistentinnen2 from "../../../assets/assistentinnen/assistentinnen_team_2.jpg";
import assistentinnen4 from "../../../assets/assistentinnen/assistentinnen_team_4.jpg";


import anmeldung1 from "../../../assets/anmeldung/anmeldung_team_1.jpg";
import anmeldung3 from "../../../assets/anmeldung/anmeldung_team_3.jpg";
import anmeldung4 from "../../../assets/anmeldung/anmeldung_team_4.jpg";
import anmeldung5 from "../../../assets/anmeldung/anmeldung_team_5.jpg";

import auszubildenden1 from "../../../assets/auszubildenden/auszubildenden_team_1.jpg";
import auszubildenden3 from "../../../assets/auszubildenden/auszubildenden_team_3.jpg";
import Tooltip from '@material-ui/core/Tooltip';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);


class Team extends Component {
  constructor(props) {
    super(props);
  
  }



  render() {
    const { classes } = this.props;

    return (
      <div style={{ marginBottom: "4vh" }}>
        <Helmet>
          <title>Team</title>
          <meta
            name="description"
            content="Unsere motivierten Mitarbeiter bilden ein starkes Team"
          />
        </Helmet>
        <div style={{ textAlign: "center" }}>
          <div className={classes.responsive}>
            <div style={{ marginTop: "2vh" }}>
              <div style={{ width: "100%" }}>
                <p className={classes.headlines}>Unser Team</p>
                <br />
                <div className={classes.flowText} style={{ marginBottom: 40 }}>
                  Unsere motivierten Mitarbeiter bilden ein starkes Team.
                  Familiäre Atomsphäre und ein konstruktives Miteinander
                  zeichnen uns aus. Das zeigt sich in langjähriger
                  Praxiszugehörigkeit und Zuwachs von jungem, interessiertem
                  Nachwuchs.
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                  marginTop: 80,
                }}
              >
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  style={{ width: "60%" }}
                  transition={{ duration: 0.4 }}
                >
                  <img
                    src={zahnaerzte}
                    style={{
                      width: "100%",
                      boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
                    }}
                  />
                </motion.div>
                <div
                  className={classes.flowText}
                  style={{ width: "40%", marginLeft: 60 }}
                >
                  <span style={{ fontWeight: "bold", fontSize: "2rem" }}>
                    {" "}
                    Unsere Zahnärzte
                  </span>
                  <Divider
                    style={{
                      height: 6,
                      width: "40%",
                      backgroundColor: "rgb(142, 66, 50)",
                    }}
                  />
                  <br />
                  <br />
                  Mit den{" "}
                  <span style={{ fontWeight: "bold" }}>
                    Dres. med. dent. Katharina Link-Löffler
                  </span>
                  , <span style={{ fontWeight: "bold" }}>Sebastian</span> und{" "}
                  <span style={{ fontWeight: "bold" }}>Paulina Link</span> ist
                  nach{" "}
                  <span style={{ fontWeight: "bold" }}>ZA Michael Link</span>{" "}
                  die zweite Generation in Krombach angekommen. Wir freuen uns
                  auf weitere 40 Jahre mit Ihnen!
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                  marginTop: 80,
                }}
              >
                <div
                  className={classes.flowText}
                  style={{ width: "40%", marginRight: 60 }}
                >
                  <span style={{ fontWeight: "bold", fontSize: "2rem" }}>
                    {" "}
                    Unsere Anmeldung
                  </span>
                  <Divider
                    style={{
                      height: 6,
                      width: "30%",
                      backgroundColor: "rgb(142, 66, 50)",
                    }}
                  />
                  <br />
                  <br />
                  <div style={{ textAlign: 'left'}}>
                  <span style={{ fontWeight: "bold" }}>
                    Mechthild Schürmann-Link
                  </span>{" "}
                  und&nbsp;
                  <span style={{ fontWeight: "bold" }}>Lisa Geis</span>
                  </div>
                  <br />
                  <br />
                  Um Ihren Aufenthalt schon von Beginn an so angenehm wie
                  möglich zu gestalten, empfangen wir Sie bereits an der
                  Anmeldung mit einem herzlichen Lächeln. Bei Fragen und
                  Terminvereinbarung sind wir hier für Sie da. Helfen Sie uns,
                  indem Sie Adress- und Datenänderungen bekannt geben.
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "40px",
                    flex: 1
                  }}
                >
                   <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  transition={{ duration: 0.4 }}
                >
                   <LightTooltip title="Mechthild Schürmann-Link" placement="bottom">
                  <img
                    
                    src={anmeldung1}
                    style={{
                      width: "100%",
                      height: '100%',
                      objectFit: 'cover',
                      boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
                    }}
                  />
                  </LightTooltip>
                </motion.div>

              

              

               

                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  transition={{ duration: 0.4 }}
                >
                                                                         <LightTooltip title="Lisa Geis" placement="bottom">

                  <img
                    src={anmeldung5}
                    style={{
                      width: "100%",
                      height: '100%',
                      objectFit: 'cover',
                      boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
                    }}
                  />
                   </LightTooltip>
                </motion.div>

                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                  marginTop: 120,
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "40px",
                    flex: 1
                  }}
                >
                   <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  transition={{ duration: 0.4 }}
                >
                                                       <LightTooltip title="Joline Augschöll" placement="bottom">

                  <img
                    src={assistentinnen1}
                    style={{
                      width: "100%",
                      height: '100%',
                      objectFit: 'cover',
                      boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
                    }}
                  />
                  </LightTooltip>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  transition={{ duration: 0.4 }}
                >
                                                       <LightTooltip title="Anna-Lena Mehling" placement="bottom">

                  <img
                    src={assistentinnen2}
                    style={{
                      width: "100%",
                      height: '100%',
                      objectFit: 'cover',
                      boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
                    }}
                  />
                  </LightTooltip>
                </motion.div>

                

               
               

                </div>

             
                <div
                  className={classes.flowText}
                  style={{ flex: 1, marginLeft: 60 }}
                >
                  <span style={{ fontWeight: "bold", fontSize: "2rem" }}>
                    {" "}
                    Unsere Assistentinnen
                  </span>
                  <Divider
                    style={{
                      height: 6,
                      width: "25%",
                      backgroundColor: "rgb(142, 66, 50)",
                    }}
                  />
                  <br />
                  <br />
                  <div style={{ textAlign: 'left'}}>

                  <span style={{ fontWeight: "bold" }}>
                  Joline Augschöll </span>  und&nbsp; <span style={{ fontWeight: "bold" }}>Anna-Lena Mehling
                  </span>{" "}
                
                  </div>
                  <br />
                  <br />
                  ... sorgen für eine angenehme und reibungslose Behandlung.
                 <br />
                  <br />
                  Sie sind unsere empathischen Allrounder.
                  
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                  marginTop: 120,
                }}
              >
               

             
                <div
                  className={classes.flowText}
                  style={{ flex: 1, marginRight: 60 }}
                >
                  <span style={{ fontWeight: "bold", fontSize: "2rem" }}>
                    {" "}
                    Unser Prophylaxeteam
                  </span>
                  <Divider
                    style={{
                      height: 6,
                      width: "25%",
                      backgroundColor: "rgb(142, 66, 50)",
                    }}
                  />
                  <br />
                  <br />
                  Unser fortgebildetes Prophylaxeteam kümmert sich um
                  vorbeugende Maßnahmen und die gründliche Reinigung Ihrer
                  Zähne. Gleichzeitig motivieren wir Sie und geben nützliche
                  Tipps für zuhause. Unsere zahnmedizinische Prävention sorgt
                  für eine anhaltende Stärkung der Zähne und lässt Ihr Lächeln
                  erstrahlen.
                  <br />
                  <br />
                  <div style={{ textAlign: 'left'}}>

                  <span style={{ fontWeight: "bold" }}>
                  Martina Bathon, Heike Heeg{" "}
                  </span>{" "}
                  und&nbsp;
                  <span style={{ fontWeight: "bold" }}>Larissa Schmidt </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "40px",
                    flex: 1
                  }}
                >
                   <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  transition={{ duration: 0.4 }}
                >
                                                       <LightTooltip title="Martina Bathon" placement="top">

                  <img
                    src={prophylaxe1}
                    style={{
                      width: "100%",
                      height: '100%',
                      objectFit: 'cover',
                      boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
                    }}
                  />
                  </LightTooltip>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  transition={{ duration: 0.4 }}
                >
                                                       <LightTooltip title="Heike Heeg" placement="top">

                  <img
                    src={prophylaxe2}
                    style={{
                      width: "100%",
                      height: '100%',
                      objectFit: 'cover',
                      boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
                    }}
                  />
                  </LightTooltip>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  transition={{ duration: 0.4 }}
                >
                                                       <LightTooltip title="Larissa Schmidt" placement="bottom">

                  <img
                    src={prophylaxe3}
                    style={{
                      width: "100%",
                      height: '100%',
                      objectFit: 'cover',
                      boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
                    }}
                  />
                  </LightTooltip>
                </motion.div>

              

                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                  marginTop: 120,
                }}
              >
                 <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  style={{ width: "60%" }}
                  transition={{ duration: 0.4 }}
                >
                  <img
                    src={dental}
                    style={{
                      width: "100%",
                      boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
                    }}
                  />
                </motion.div>
                <div
                  className={classes.flowText}
                  style={{ width: "50%", marginLeft: 60 }}
                >
                  <span style={{ fontWeight: "bold", fontSize: "2rem" }}>
                    {" "}
                    Unsere Dentalhygienikerin
                  </span>
                  <Divider
                    style={{
                      height: 6,
                      width: "25%",
                      backgroundColor: "rgb(142, 66, 50)",
                    }}
                  />
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    DH Anna Amberg (BA)
                  </span>
                  <br />
                  <br />
                  Eine dauerhafte Zahnfleischentzündung kann zu einer
                  Parodontitis, d.h. zu einer Entzündung des Zahnhalteapparates
                  bis hin zum Zahnverlust, führen. Um ein Fortschreiten der
                  Erkrankung bestmöglich zu verhindern, steht Ihnen Anna Amberg
                  zur Seite.
                  <br />
                  <br />
                  Anna Amberg berät Sie auch gerne rund um das Thema Bleaching
                  (Zahnaufhellung), um Ihre Zähnen schonend aufzuhellen.
                </div>
               
              </div>


                           <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                  marginTop: 120,
                }}
              >
                <div
                  className={classes.flowText}
                  style={{ width: "46%", marginRight: 60 }}
                >
                  <span style={{ fontWeight: "bold", fontSize: "2rem" }}>
                    {" "}
                    Unsere Auszubildenden
                  </span>
                  <Divider
                    style={{
                      height: 6,
                      width: "30%",
                      backgroundColor: "rgb(142, 66, 50)",
                    }}
                  />
                  <br />
                  <br />
                  Ramona Capogreco und Vanessa Moscatello sorgen für frischen Wind in
                  unserer Praxis. Sie bereichern unser Team mit ihrer
                  fröhlichen und offenen Art.
                  <br />
                  <br />
                  <div style={{ textAlign: 'left'}}>

                  <span style={{ fontWeight: "bold" }}>Ramona Capogreco</span>{" "}
                  und&nbsp;
                  <span style={{ fontWeight: "bold" }}>Vanessa Moscatello </span>
                  </div>
                  <br />
                  <br />
                </div>
               
             
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "40px",
                    flex: 1
                  }}
                >
                   <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  transition={{ duration: 0.4 }}
                >
                                                       <LightTooltip title="Ramona Capogreco" placement="top">

                  <img
                    src={auszubildenden1}
                    style={{
                      width: "100%",
                      height: '100%',
                      objectFit: 'cover',
                      boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
                    }}
                  />
                  </LightTooltip>
                </motion.div>

               
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  transition={{ duration: 0.4 }}
                >
                                                       <LightTooltip title="Vanessa Moscatello" placement="bottom">

                  <img
                    src={auszubildenden3}
                    style={{
                      width: "100%",
                      height: '100%',
                      objectFit: 'cover',
                      boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
                    }}
                  />
                  </LightTooltip>
                </motion.div>

              

                </div>
              </div>

              
            

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                  marginTop: 120,
                  marginBottom: 60,
                }}
              >
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  style={{ width: "60%" }}
                  transition={{ duration: 0.4 }}
                >
                  <img
                    src={verwaltung}
                    style={{
                      width: "100%",
                      boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
                    }}
                  />
                </motion.div>
                <div
                  className={classes.flowText}
                  style={{  width: "40%", marginLeft: 60 }}
                >
                  <span style={{ fontWeight: "bold", fontSize: "2rem" }}>
                    {" "}
                    Unser Verwaltungsteam
                  </span>
                  <Divider
                    style={{
                      height: 6,
                      width: "33%",
                      backgroundColor: "rgb(142, 66, 50)",
                    }}
                  />
                  <br />
                  <br />
                  <div style={{ textAlign: 'left'}}>

                  Magdalena Stenger und Anna-Lena Mehling sorgen für einen
                  reibungslosen Ablauf hinter den Kulissen.
                  <br />
                  <br />
                  <span style={{ fontWeight: "bold" }}>
                    Magdalena Stenger{" "}
                  </span>{" "}
                  und&nbsp;
                  <span style={{ fontWeight: "bold" }}>Anna-Lena Mehling </span>
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Team);
