import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import "../../../App.css";
import CustomCarousel from './carousel.js'

import nachfolge from '../../../assets/nachfolge.jpg';
import willkommen_in_der_praxis from '../../../assets/willkommen_in_der_praxis.jpg';
import gruppenfoto from '../../../assets/mobile/Gruppenfoto.png'
import {styles} from '../../../breakpoints';

import ButtonBase from '@material-ui/core/ButtonBase';
import * as ROUTES from '../../../constants/routes';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Helmet} from "react-helmet";


class HomePageMobile extends Component {


  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 0,
    windowHeight: 0
    }
    this.updateDimensions = this.updateDimensions.bind(this);

  }


  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    let width = typeof window !== "undefined" ? window.innerWidth : 0;
    let height = typeof window !== "undefined" ? window.innerHeight : 0;
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }



  render(){
    const {
windowWidth,
windowHeight
} = this.state;
const { classes, theme } = this.props;
  console.log(windowHeight)
    return(
        <div style={{marginTop: 112}}>
        <Helmet>
      <title>Zahnarztpraxis Link in Krombach</title>
      <meta name="description" content="Herzlich Willkommen bei den Zahnärzten Link in 63829 Krombach. Ihrer Anlaufstelle, wenn es um kompetente Beratung, Behandlung und Zahnheilkunde geht. Vereinbaren Sie noch heute einen Termin oder besuchen Sie uns direkt vor Ort." />
   </Helmet>
        {windowWidth === 0 ?

          <div style={{height: '100vh', display: 'flex', justifyContent: 'center'}}>
      <CircularProgress style={{alignSelf: 'center', color: '#333'}}/>
    </div>
        :
          <div>
        <CustomCarousel height={windowHeight} width={windowWidth} />
        <div>
      <br/>
      <div className={classes.headlines} style={{padding: "0px 16px"}}>Herzlich Willkommen bei den Zahnärzten Link </div>
      <div className={classes.flowText} style={{padding: "0px 16px"}}>

    <br/>
    Die	Zahnarztpraxis Link praktiziert in der zweiten
Generation und ist seit über 30 Jahren in Krombach für Sie vor Ort tätig.
        <br/>
        </div>
       <img src={willkommen_in_der_praxis} style={{width: '100%', marginTop: 30, marginBottom: 30}}/>
       <div className={classes.flowText} style={{padding: "0px 16px"}}>
       In unserer Praxis beraten wir Sie gerne über das
gesamte <span style={{fontWeight: 'bold'}}>zahnmedizinische Spektrum</span>.
Wir freuen uns Ihnen von der konservierenden über die chirurgische bis hin zur prothetischen Zahnmedizin alle Facetten einer modernen, patientengerechten Versorgung ermöglichen zu können.
<br/>
<br/>

Auch informieren wir Sie gerne über den Einsatz von <span style={{fontWeight: 'bold'}}>Protrusionsschienen</span> (Schnarcherschienen), <span style={{fontWeight: 'bold'}}>adjustierten Aufbissschienen</span> (Knirscherschienen), sowie <span style={{fontWeight: 'bold'}}>Bleachingschienen</span> (Zahnaufhellungschienen).


            <br/>
            </div>
            <img src={nachfolge} style={{width: '100%', marginTop: 30, marginBottom: 30}}/>
            <div className={classes.flowText} style={{padding: "0px 16px"}}>
            In unserer Praxis verbinden sich <span style={{fontWeight: 'bold'}}>Erfahrung </span> und <span style={{fontWeight: 'bold'}}>Innovation </span> in einem
familiären Umfeld. Die Nachfolge
ist durch Dres. med. dent.
Katharina Link-Löffler, Sebastian und Paulina
Link gesichert.
<br/>
</div>
<ButtonBase  onClick={() => this.props.history.push(ROUTES.TEAM)} style={{marginTop: 30, marginBottom: 30}}>
<div>
<img src={gruppenfoto} style={{width: '100%'}}/>

</div>
</ButtonBase>
        </div>
        </div>
      }
        </div>

      );

  }
}




export default (withStyles(styles, { withTheme: true })(HomePageMobile));
