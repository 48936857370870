import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import ArrowDown from "@material-ui/icons/KeyboardArrowDown";
import logo from "../assets/logo.svg";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import Phone from "@material-ui/icons/Phone";
import { history } from "./history.js";
import * as ROUTES from "../constants/routes";
import ButtonBase from "@material-ui/core/ButtonBase";
import Link from "@material-ui/core/Link";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    backgroundColor: "#ffffff",
    width: "60%",
  },
  toolbar: {
    marginTop: 20,
    minHeight: 130,
    alignItems: "flex-start",
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
  },

  appBar: {
    backgroundColor: "#ffffff",
    height: 112,
    position: "fixed",
  },

  root: {
    "&::before": {
      height: 0,
    },
  },
}));

function ElevationScroll(props) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 56,
  });
  return React.cloneElement(props.children, {
    style: {
      boxShadow: trigger ? "0 4px 2px -2px rgba(0, 0, 0, 0.3)" : "none",
    },
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

export default function ProminentAppBarMobile() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function onClickLeistung(Leistung) {
    history.push(Leistung);
    setOpen(false);
  }

  const checkIfOpen = () => {
    var startTimeMorning = "08:00:00";
    var endTimeMorning = "12:00:00";

    var startTimeEvening = "14:00:00";
    var endTimeEvening = "18:00:00";

    var currentDate = new Date();

    var isWednesdayAfternoon =
      currentDate.getDay() === 3 &&
      currentDate.getHours() >= 14 &&
      currentDate.getHours() < 18;

    if (
      currentDate.getDay() === 6 ||
      currentDate.getDay() === 0 ||
      isWednesdayAfternoon
    ) {
      return false;
    }

    var startDateMorning = new Date(currentDate.getTime());
    startDateMorning.setHours(startTimeMorning.split(":")[0]);
    startDateMorning.setMinutes(startTimeMorning.split(":")[1]);
    startDateMorning.setSeconds(startTimeMorning.split(":")[2]);

    var endDateMorning = new Date(currentDate.getTime());
    endDateMorning.setHours(endTimeMorning.split(":")[0]);
    endDateMorning.setMinutes(endTimeMorning.split(":")[1]);
    endDateMorning.setSeconds(endTimeMorning.split(":")[2]);

    var startDateEvening = new Date(currentDate.getTime());
    startDateEvening.setHours(startTimeEvening.split(":")[0]);
    startDateEvening.setMinutes(startTimeEvening.split(":")[1]);
    startDateEvening.setSeconds(startTimeEvening.split(":")[2]);

    var endDateEvening = new Date(currentDate.getTime());
    endDateEvening.setHours(endTimeEvening.split(":")[0]);
    endDateEvening.setMinutes(endTimeEvening.split(":")[1]);
    endDateEvening.setSeconds(endTimeEvening.split(":")[2]);

    var valid =
      (startDateMorning < currentDate && endDateMorning > currentDate) ||
      (startDateEvening < currentDate && endDateEvening > currentDate);

    return valid;
  };

  return (
    <div>
      <Drawer
        onClose={handleDrawerClose}
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List>
          <div
            style={{
              margin: "auto auto 0px auto",
              padding: 8,
              textAlign: "center",
            }}
          >
            <span
              style={{
                color: "#13294b",
                fontWeight: "bold",
                fontSize: "1.1rem",
                textAlign: "left",
              }}
            >
              Öffnungszeiten{" "}
            </span>
            <div style={{ fontSize: "1rem", marginTop: 10 }}>
              {" "}
              Montag - Freitag
              <br />
              08.00 - 12.00 Uhr
              <br />
              14.00 - 18.00 Uhr
              <br />
              Mittwoch Nachmittag geschlossen
              {checkIfOpen() ? (
                <div
                  style={{
                    fontWeight: "bold",
                    color: "green",
                    fontSize: "1.2rem",
                    textAlign: "center",
                    marginTop: 8,
                  }}
                >
                  Geöffnet
                </div>
              ) : (
                <div
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    fontSize: "1.2rem",
                    textAlign: "center",
                    marginTop: 8,
                  }}
                >
                  Geschlossen
                </div>
              )}
              <ButtonBase
                href="tel:060242220"
                style={{
                  fontSize: "1.2rem",
                  color: "#13294b",
                  marginLeft: "-1rem",
                }}
              >
                <Phone
                  style={{
                    marginRight: "1.5vw",
                    height: "1.3rem",
                    width: "1.3rem",
                  }}
                />
                06024 / 2220
              </ButtonBase>
            </div>
          </div>
          <Divider />

          <ListItem button onClick={() => onClickLeistung(ROUTES.HOME)}>
            <ListItemText primary="Startseite" />
          </ListItem>
          <ListItem button onClick={() => onClickLeistung(ROUTES.INFOS)}>
            <ListItemText primary="Infos" />
          </ListItem>
          <ListItem button onClick={() => onClickLeistung(ROUTES.TEAM)}>
            <ListItemText primary="Unser Team" />
          </ListItem>
          <ListItem button onClick={() => onClickLeistung(ROUTES.BEWERBUNGEN)}>
            <ListItemText primary="Bewerbungen" />
          </ListItem>
          <ListItem button onClick={() => onClickLeistung(ROUTES.KONTAKT)}>
            <ListItemText primary="Kontakt" />
          </ListItem>
        </List>
        <List style={{ marginTop: "auto" }}>
          <Divider />

          <ListItem button onClick={() => onClickLeistung(ROUTES.DATENSCHUTZ)}>
            <ListItemText primary="Datenschutz" />
          </ListItem>

          <ListItem button onClick={() => onClickLeistung(ROUTES.IMPRESSUM)}>
            <ListItemText primary="Impressum" />
          </ListItem>
        </List>
      </Drawer>
      <ElevationScroll>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <IconButton
              onClick={handleDrawerOpen}
              edge="end"
              className={classes.menuButton}
              style={{
                height: 56,
                position: "absolute",
                right: 16,
                top: 56,
                color: "#13294b",
              }}
              color="inherit"
              aria-label="menu"
            >
              <div style={{ fontSize: "1rem", marginRight: 5 }}>MENÜ</div>
              <MenuIcon style={{ color: "#13294b" }} />
            </IconButton>
            <ButtonBase
              onClick={() => history.push(ROUTES.HOME)}
              style={{ height: 80, position: "absolute", left: 15, top: 15 }}
            >
              <img src={logo} style={{ height: "100%" }} />
            </ButtonBase>
            <ButtonBase
              href="tel:060242220"
              style={{
                fontSize: "20px",
                marginLeft: "auto",
                display: "inline-flex",
                color: "#13294b",
              }}
            >
              <Phone
                style={{
                  marginRight: "2vw",
                  height: "20px",
                  width: "20px",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              />
              06024 / 2220
            </ButtonBase>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </div>
  );
}
