import React, { Component } from "react";
import logo from "../../assets/pin.png";

import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "55vh",
};

export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  };

  onMarkerClick = (props, marker, e) => {
    console.log(props);
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  onInfoWindowClose = (props) => {
    this.setState({
      showingInfoWindow: false,
      activeMarker: null,
    });
  };

  render() {
    return (
      <Map
        google={this.props.google}
        zoom={18}
        initialCenter={{
          lat: 50.085902210857334,
          lng: 9.20546080009198,
        }}
        containerStyle={containerStyle}
        onClick={this.onMapClicked}
      >
        <Marker
          position={{ lat: 50.085902210857334, lng: 9.20546080009198 }}
          onClick={this.onMarkerClick}
          name={"Zahnärzte Link"}
          icon={{
            url: logo,
            anchor: new this.props.google.maps.Point(32, 32),
            scaledSize: new this.props.google.maps.Size(48, 48),
          }}
        />

        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onInfoWindowClose}
        >
          <div>
            <p style={{ fontSize: "1rem", fontWeight: "bold" }}>
              Zahnarztpraxis Link
            </p>
            <p style={{ marginTop: 10, marginBottom: 10 }}>
              {" "}
              Ullastr. 3
              <br />
              63829 Krombach
            </p>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://maps.app.goo.gl/92NvNtgWtKPRnV1P8"
            >
              {" "}
              In Google Maps öffnen
            </a>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCUY-ahsrWC-kk9wJpLxAKENpsXwM6kjJ4",
  language: "de",
})(MapContainer);
