export const HOME = '/';
export const LEISTUNG1 = '/leistung1';
export const LEISTUNG2 = '/leistung2';
export const LEISTUNG3 = '/leistung3';
export const TEAM = '/team';
export const KONTAKT = '/kontakt';
export const IMPRESSUM = '/impressum';
export const DATENSCHUTZ = '/datenschutz';
export const INFOS = '/infos';
export const BEWERBUNGEN = '/bewerbungen';
