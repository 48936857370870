import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import "../../../App.css";
import {Helmet} from "react-helmet";


import {styles} from '../../../breakpoints';



class Datenschutz extends Component {


  constructor(props) {
    super(props);
    this.state = {

    }
    this.updateDimensions = this.updateDimensions.bind(this);

  }


  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

    this.setState({ windowWidth, windowHeight });
  }



  render(){
    const {
windowWidth,
windowHeight
} = this.state;
const { classes } = this.props;

    return(
      <div style={{marginBottom: '4vh'}}>
      <Helmet>
    <title>Datenschutz</title>
   <meta name="description" content="Hier erfährst du alles über unseren Datenschutz." />
 </Helmet>

        <div style={{textAlign: 'center'}}>

          <div className={classes.responsive}>
          <div style={{  marginTop: '2vh'}}>

            <div style={{width: '100%'}}>
            <p className={classes.headlines}>Datenschutzerklärung</p>
            <br/>
            <p className={classes.flowHeadline}> 1. Allgemeine Hinweise </p>
            <br/>
            <p className={classes.flowText}>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
  passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
  persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
  Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>
<br/>
<br/>
<p className={classes.flowSubHeadline}>Datenerfassung auf dieser Website</p>

<br/>

              <p style={{fontWeight: "bold", textAlign: 'left', fontSize: '1.2rem'}}>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</p>
              <br/>
              <p className={classes.flowText}>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
können Sie dem Impressum dieser Website entnehmen.</p>
  <br/>
  <p style={{fontWeight: "bold", textAlign: 'left', fontSize: '1.2rem'}}>Wie erfassen wir Ihre Daten?</p>


                              <br/>

                            <p className={classes.flowText}>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um
  Daten handeln, die Sie in ein Kontaktformular eingeben.
  Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-
Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit

des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.

                      </p>
                      <br/>
                      <p style={{fontWeight: "bold", textAlign: 'left', fontSize: '1.2rem'}}>Wofür nutzen wir Ihre Daten?</p>
                      <br/>
                      <p className={classes.flowText}>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>
<br/>
<p style={{fontWeight: "bold", textAlign: 'left', fontSize: '1.2rem'}}>Welche Rechte haben Sie bezüglich Ihrer Daten?</p>
<br/>
<p className={classes.flowText}>Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
</p>
<br/>
<p className={classes.flowText}>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum
angegebenen Adresse an uns wenden.</p>
<br/>
<br/>
<p className={classes.flowSubHeadline}>Analyse-Tools und Tools von Drittanbietern</p>
<br/>
<p className={classes.flowText}>
Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor
allem mit Cookies und mit sogenannten Analyseprogrammen.
<br/>
 Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden
Datenschutzerklärung.</p>
<br/>
<br/>
<p className={classes.flowHeadline}> 2. Hosting und Content Delivery Networks (CDN) </p>
<br/>
<br/>
<p className={classes.flowSubHeadline}>Externes Hosting</p>
<br/>
<p className={classes.flowText}>Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die
auf dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v.
a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten,
Namen, Webseitenzugriffe und sonstige Daten, die über eine Website generiert werden, handeln.
<br/>
<br/>
Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und
bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
<br/>
<br/>
Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten
erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.</p>
<br/>
<br/>
<p className={classes.flowHeadline}> 3. Allgemeine Hinweise und Pflichtinformationen </p>
<br/>
<br/>
<p className={classes.flowSubHeadline}>Datenschutz</p>
<br/>
<p className={classes.flowText}>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie
dieser Datenschutzerklärung.
<br/>
<br/>
Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
und zu welchem Zweck das geschieht.
<br/>
<br/>
Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
möglich.</p>
<br/>
<br/>
<p className={classes.flowSubHeadline}>Hinweis zur verantwortlichen Stelle</p>
<br/>
<p className={classes.flowText}> Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
<br/>
<p style={{textAlign: 'left', fontWeight: 'bold', marginLeft: 50, fontSize: '1.2rem'}}>        Zahnärzte Michael Link und Heinz-Georg Schneider ÜBAG

<br/>
Ullastr. 3
<br/>
63829 Krombach
<br/>
<br/>
Telefon: 06024 / 2220
<br/>
E-Mail: info@zahnarztpraxis-link.de
</p>
<br/>
<p className={classes.flowText}>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
entscheidet.</p>
  <br/>
  <br/>
  <p className={classes.flowSubHeadline}>Widerruf Ihrer Einwilligung zur Datenverarbeitung</p>
  <br/>
  <p className={classes.flowText}>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns.
Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
<br/>
<br/>
<p className={classes.flowSubHeadline}>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
Direktwerbung (Art. 21 DSGVO)</p>
<br/>
<p className={classes.flowText}>WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN,
WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
<br/>
<br/>
WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
NACH ART. 21 ABS. 2 DSGVO).</p>
<br/>
<br/>
<p className={classes.flowSubHeadline}>    Beschwerderecht bei der zuständigen Aufsichtsbehörde</p>
<br/>

<p className={classes.flowText}>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p>
<br/>
<br/>
<p className={classes.flowSubHeadline}>Recht auf Datenübertragbarkeit</p>
<br/>

<p className={classes.flowText}>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
<br/>
<br/>
<p className={classes.flowSubHeadline}>SSL- bzw. TLS-Verschlüsselung</p>
<br/>

<p className={classes.flowText}>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum

Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-
Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von

„http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
<br/>
<br/>
Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht
von Dritten mitgelesen werden.</p>
<br/>
<br/>
<p className={classes.flowSubHeadline}>Auskunft, Löschung und Berichtigung</p>
<br/>

<p className={classes.flowText}>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie
zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum
angegebenen Adresse an uns wenden.</p>
<br/>
<br/>
<p className={classes.flowSubHeadline}>Recht auf Einschränkung der Verarbeitung</p>
<br/>

<p className={classes.flowText}>
Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
Hierzu können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht
auf Einschränkung der Verarbeitung besteht in folgenden Fällen:</p>
<br/>
<br/>
<ul className={classes.flowText} style={{marginLeft: 50}}>
<li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
<li> Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie
statt der Löschung die Einschränkung der Datenverarbeitung verlangen.</li>
<li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
<li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
zu verlangen.</li>
</ul>
<br/>
<br/>
<p className={classes.flowText}>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
eines Mitgliedstaats verarbeitet werden.</p>
<br/>
<br/>
<p className={classes.flowHeadline}> 4. Datenerfassung auf dieser Website </p>
<br/>
<br/>
<p className={classes.flowSubHeadline}>Cookies</p>
<br/>

<p className={classes.flowText}>Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Textdateien und richten auf
Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung
(Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies
werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.
<br/>
<br/>
Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie
unsere Seite betreten (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung bestimmter
Dienstleistungen des Drittunternehmens (z.B. Cookies zur Abwicklung von Zahlungsdienstleistungen).
<br/>
<br/>
Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte
Webseitenfunktionen ohne diese nicht funktionieren würden (z.B. die Warenkorbfunktion oder die Anzeige
von Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung anzuzeigen.
<br/>
<br/>
Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs (notwendige Cookies) oder zur
Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies, z. B. für die
Warenkorbfunktion) oder zur Optimierung der Webseite (z.B. Cookies zur Messung des Webpublikums)
erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung
von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine
Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der betreffenden
Cookies ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist
jederzeit widerrufbar.
<br/>
<br/>
Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und
Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen
sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
<br/>
<br/>
Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hierüber
im Rahmen dieser Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.</p>
<br/>
<br/>
<p className={classes.flowSubHeadline}>Kontaktformular</p>
<br/>

<p className={classes.flowText}>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage
und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
Einwilligung weiter.
<br/>
<br/>
Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
<br/>
<br/>
Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
(z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –
insbesondere Aufbewahrungsfristen – bleiben unberührt.</p>
<br/>
<br/>
<p className={classes.flowSubHeadline}>Anfrage per E-Mail, Telefon oder Telefax</p>
<br/>

<p className={classes.flowText}>Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens
bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
<br/>
<br/>
Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
<br/>
<br/>
Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
(z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.</p>
<br/>
<br/>
<p className={classes.flowHeadline}> 5. Plugins und Tools </p>
<br/>
<br/>
<p className={classes.flowSubHeadline}>Google Analytics</p>
<br/>
<p className={classes.flowText}>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. ("Google"). Google Analytics verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung  auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Wir weisen Sie darauf hin, dass auf dieser Website Google Analytics um den Code "gat._anonymizeIp();" erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten.
<br/>
<br/>
Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA Übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
<br/>
<br/>
Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
 Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:
</p>


<a href="http://tools.google.com/dlpage/gaoptout?hl=de"><p style={{textAlign: 'left'}}>http://tools.google.com/dlpage/gaoptout?hl=de</p></a>
<br/>
<br/>
<p className={classes.flowText}>Auch können Sie die Erfassung durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert:</p>
<a href="javascript:gaOptout()"><p style={{textAlign: 'left'}}>Google Analytics deaktivieren</p></a>.
<br/>
<br/>
<p className={classes.flowText} >Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter: </p>

<a href="http://www.google.com/analytics/terms/de.html"><p style={{textAlign: 'left'}}>http://www.google.com/analytics/terms/de.html</p></a>
<br/>
<p className={classes.flowText}> Bzw. unter: </p>

  <a href="https://www.google.de/intl/de/policies/"><p style={{textAlign: 'left'}}>https://www.google.de/intl/de/policies/</p></a>

<br/>
<br/>
<p className={classes.flowSubHeadline}>Google Maps</p>
<br/>

<p className={classes.flowText}>Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited
(„Google“), Gordon House, Barrow Street, Dublin 4, Irland.
<br/>
<br/>
Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese
Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
<br/>
<br/>
Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-
Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt

ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende
Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
DSGVO; die Einwilligung ist jederzeit widerrufbar.
<br/>
<br/>
Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google:</p>


<a  href="https://policies.google.com/privacy?hl=de"><p style={{textAlign: 'left'}}>https://policies.google.com/privacy?hl=de</p></a>
<br/>

<br/>












                      </div>
          </div>

            </div>

        </div>
      </div>
      );

  }
}




export default (withStyles(styles, { withTheme: true })(Datenschutz));
