import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CustomCarousel from "./carousel.js";
import "../../../App.css";
import { motion } from "framer-motion";

import nachfolge from "../../../assets/nachfolge.jpg";
import willkommen_in_der_praxis from "../../../assets/willkommen_in_der_praxis.jpg";
import gruppenfoto from "../../../assets/team.png";
import ButtonBase from "@material-ui/core/ButtonBase";
import * as ROUTES from "../../../constants/routes";

import { styles } from "../../../breakpoints";
import { Helmet } from "react-helmet";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 0,
      windowHeight: 0,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

    this.setState({ windowWidth, windowHeight });
  }

  render() {
    const { windowWidth, windowHeight } = this.state;
    const { classes } = this.props;

    return (
      <div style={{ marginBottom: "4vh" }}>
        <Helmet>
          <title>Zahnarztpraxis Link in Krombach</title>
          <meta
            name="description"
            content="Herzlich Willkommen bei den Zahnärzten Link in 63829 Krombach. Ihrer Anlaufstelle, wenn es um kompetente Beratung, Behandlung und Zahnheilkunde geht. Vereinbaren Sie noch heute einen Termin oder besuchen Sie uns direkt vor Ort."
          />
        </Helmet>
        <div style={{ textAlign: "center" }}>
          <CustomCarousel height={windowHeight} width={windowWidth} />
          <div className={classes.responsive}>
            <p className={classes.flowText}>
              <br />
              <span className={classes.headlines}>
                Herzlich Willkommen bei den Zahnärzten Link{" "}
              </span>
              <br />
              <br />
              Die Zahnarztpraxis Link praktiziert in der zweiten Generation und
              ist seit über 30 Jahren in Krombach für Sie vor Ort tätig.
              <br />
              <br />
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2vh",
              }}
            >
              <motion.div
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 1 }}
                style={{ width: "45%" }}
                transition={{ duration: 0.4 }}
              >
                <img
                  alt="Willkommen"
                  src={willkommen_in_der_praxis}
                  style={{
                    width: "100%",
                    boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
                  }}
                />
              </motion.div>

              <p className={classes.flowText} style={{ width: "45%" }}>
                In unserer Praxis beraten wir Sie gerne über das gesamte{" "}
                <span style={{ fontWeight: "bold" }}>
                  zahnmedizinische Spektrum
                </span>
                . Wir freuen uns Ihnen von der konservierenden über die
                chirurgische bis hin zur prothetischen Zahnmedizin alle Facetten
                einer modernen, patientengerechten Versorgung ermöglichen zu
                können.
                <br />
                <br />
                Auch informieren wir Sie gerne über den Einsatz von{" "}
                <span style={{ fontWeight: "bold" }}>Protrusionsschienen </span>
                (Schnarcherschienen),{" "}
                <span style={{ fontWeight: "bold" }}>
                  adjustierten Aufbissschienen{" "}
                </span>{" "}
                (Knirscherschienen), sowie
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  Bleachingschienen{" "}
                </span>{" "}
                (Zahnaufhellungschienen).
                <br />
                <br />
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "6vh",
              }}
            >
              <p className={classes.flowText} style={{ width: "45%" }}>
                In unserer Praxis verbinden sich{" "}
                <span style={{ fontWeight: "bold" }}>Erfahrung</span> und{" "}
                <span style={{ fontWeight: "bold" }}>Innovation</span> in einem
                familiären Umfeld. Mit den Dres. med. dent. Katharina
                Link-Löffler, Sebastian und Paulina Link ist die Zukunft
                gesichert.
              </p>

              <div style={{ width: "45%" }}>
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  transition={{ duration: 0.4 }}
                >
                  <img
                    alt="Nachfolge"
                    src={nachfolge}
                    style={{
                      width: "100%",
                      boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
                    }}
                  />
                </motion.div>
              </div>
            </div>
            <p className={classes.flowText}>
              <br />
              <span className={classes.headlines}>
                {" "}
                Wir freuen uns auf Ihren Besuch!{" "}
              </span>
              <br />
              <br />
            </p>
            <motion.div
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 1 }}
              transition={{ duration: 0.4 }}
            >
              <ButtonBase
                onClick={() => this.props.history.push(ROUTES.TEAM)}
                style={{ width: "100%" }}
              >
                <img
                  alt="Unser Team"
                  src={gruppenfoto}
                  style={{
                    width: "100%",
                    boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
                  }}
                />
              </ButtonBase>
            </motion.div>

            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HomePage);
