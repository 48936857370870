import { combineReducers } from 'redux'

import sliderPage, * as fromSliderPage  from './slideScreen'
import coronaDialog, * as fromCoronaDialog  from './coronadialog'

export default combineReducers({

  sliderPage,
  coronaDialog


})

export const checkSliderpage = state => fromSliderPage.checkSliderpage(state.sliderPage)
export const checkCoronaDialog = state => fromCoronaDialog.checkCoronaDialog(state.coronaDialog)
