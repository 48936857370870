import React from "react";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import { history } from "./history.js";
import * as ROUTES from "../constants/routes.js";
import { withStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import ScrollLogo from "./scrollLogo.js";
import { styles } from "../breakpoints.js";

import {
  motion,
  useViewportScroll,
  useSpring,
  useTransform,
} from "framer-motion";

function ElevationScroll(props) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 150,
  });
  return React.cloneElement(props.children, {
    style: {
      boxShadow: trigger ? "0 4px 2px -2px rgba(0, 0, 0, 0.3)" : "none",
      zIndex: 300,
    },
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

export const ScrollIndicator = () => {
  const [isComplete, setIsComplete] = useState(false);
  const { scrollYProgress } = useViewportScroll();
  const yRange = useTransform(scrollYProgress, [0, 1], [0, 1]);
  const pathLength = useSpring(yRange, { stiffness: 400, damping: 90 });

  useEffect(() => yRange.onChange((v) => setIsComplete(v >= 1)), [yRange]);
  return (
    <svg style={{ width: "100%", height: "100%" }}>
      <motion.path
        fill="none"
        strokeWidth="5"
        stroke="rgb(19, 41, 75)"
        strokeDasharray="0 1"
        d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
        style={{
          pathLength,
          rotate: 90,
          translateX: 5,
          translateY: 5,
          scaleX: -1, // Reverse direction of line animation
        }}
      />
      <motion.path
        fill="none"
        strokeWidth="5"
        stroke="rgb(19, 41, 75)"
        d="M14,26 L 22,33 L 35,16"
        initial={false}
        strokeDasharray="0 1"
        animate={{ pathLength: isComplete ? 1 : 0 }}
      />
    </svg>
  );
};

export function MenuListComposition() {
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const anchorRef1 = React.useRef(null);
  const anchorRef2 = React.useRef(null);
  const anchorRef3 = React.useRef(null);

  const mouseOverButton = () => {
    setDisabled(true);
    setOpen1(true);
  };

  const mouseLeaveButton = () => {
    setDisabled(false);
    setOpen1(false);
  };

  const handleToggle1 = (event) => {
    setOpen1(true);
  };

  const handleClose1 = (event) => {
    setOpen1(false);
  };

  function handleListKeyDown1(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1(false);
    }
  }

  const handleToggle2 = (event) => {
    setOpen2(true);
  };

  const handleClose2 = (event) => {
    setOpen2(false);
  };

  function handleListKeyDown2(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen2(false);
    }
  }

  const handleToggle3 = (event) => {
    setOpen3(true);
  };

  const handleClose3 = (event) => {
    setOpen3(false);
  };

  function handleListKeyDown3(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen3(false);
    }
  }

  function onClickLeistung(Leistung) {
    history.push(Leistung);
    setOpen1(false);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginLeft: "auto",
        height: "100%",
        marginTop: "auto",
        marginBottom: "auto",
      }}
    >
      <Button
        aria-haspopup="true"
        onClick={() => history.push(ROUTES.HOME)}
        style={{
          marginRight: "3vw",
          fontSize: "1.2rem",
          lineHeight: "normal",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        Startseite
      </Button>
      <Button
        aria-haspopup="true"
        onClick={() => history.push(ROUTES.INFOS)}
        style={{
          marginRight: "3vw",
          fontSize: "1.2rem",
          lineHeight: "normal",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        Infos
      </Button>
      {/*
        <Button
          ref={anchorRef1}
          aria-controls={open1 ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle1}
          onMouseEnter={mouseOverButton}
          onMouseLeave={mouseLeaveButton}
          style={{marginRight: '3vw', fontSize: '1.2rem', marginTop: 'auto', marginBottom: 'auto', lineHeight: 'normal'}}
        >
          Leistungen
          <ArrowDown style={{height: '1.2em', width: '1.2em'}}/>
        </Button>
        <Popper           onMouseEnter={handleToggle1}
 open={open1} anchorEl={anchorRef1.current} role={undefined} transition disablePortal style={{zIndex: 5}}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <div style={{backgroundColor: '#ffffff', boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.5)'}}>
                <ClickAwayListener onClickAway={ () => !disabled && handleClose1}>
                  <MenuList autoFocusItem={open1} id="menu-list-grow" onKeyDown={handleListKeyDown1} onMouseLeave={handleClose1} style={{color: '#000'}}>
                    <MenuItem onClick={() => onClickLeistung(ROUTES.LEISTUNG1)} style={{fontSize: '1.2rem', letterSpacing: '0.02857em', marginBottom: '0.5vh'}}>LEISTUNG 1</MenuItem>
                    <MenuItem onClick={() => onClickLeistung(ROUTES.LEISTUNG2)} style={{fontSize: '1.2rem', letterSpacing: '0.02857em', marginBottom: '0.5vh'}}>LEISTUNG 2</MenuItem>
                    <MenuItem onClick={() => onClickLeistung(ROUTES.LEISTUNG3)} style={{fontSize: '1.2rem', letterSpacing: '0.02857em'}}>LEISTUNG 3</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </div>
            </Grow>
          )}
        </Popper>
        */}
      <Button
        aria-haspopup="true"
        onClick={() => history.push(ROUTES.TEAM)}
        style={{
          marginRight: "3vw",
          fontSize: "1.2rem",
          lineHeight: "normal",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        Unser Team
      </Button>

      <Button
        aria-haspopup="true"
        onClick={() => history.push(ROUTES.BEWERBUNGEN)}
        style={{
          marginRight: "3vw",
          fontSize: "1.2rem",
          lineHeight: "normal",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        Bewerbungen
      </Button>

      <Button
        aria-haspopup="true"
        onClick={() => history.push(ROUTES.KONTAKT)}
        style={{
          fontSize: "1.2rem",
          lineHeight: "normal",
          paddingRight: 0,
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        Kontakt
      </Button>
    </div>
  );
}

class Navigation extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <ElevationScroll>
        <div className="NavigationBar">
          <div
            style={{
              position: "absolute",
              height: 50,
              width: 50,
              right: 20,
              top: 0,
              bottom: 0,
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <ScrollIndicator />
          </div>
          <div className={classes.responsive}>
            <div style={{ display: "flex", height: "80px" }}>
              <MenuListComposition />
            </div>
            <ScrollLogo />
          </div>
        </div>
      </ElevationScroll>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Navigation);
