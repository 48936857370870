import React from 'react';

import slider1mobile from '../../../assets/slider1_mobile.jpg';
import slider2mobile from '../../../assets/slider2_mobile.jpg';
import slider3mobile from '../../../assets/slider3_mobile.jpg';
import slider4mobile from '../../../assets/slider4_mobile.jpg';
import slider5mobile from '../../../assets/slider5_mobile.jpg';
import slider1 from '../../../assets/slider1_ipad.jpg';
import slider2 from '../../../assets/slider2_ipad.jpg';
import slider3 from '../../../assets/slider3_ipad.jpg';
import slider4 from '../../../assets/slider4_ipad.jpg';
import slider5 from '../../../assets/slider5_ipad.jpg';

import Fab from '@material-ui/core/Fab';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowDown from '@material-ui/icons/ExpandMore';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const styles = theme => ({


  button: {
backgroundColor: 'rgba(145, 145, 145, 0.4)',
color: '#ffffff',
width: 50,
height: 50,
 borderRadius: '30px',
 borderWidth: 0,
 outline: 'none'
  }


});



 class CustomCarousel extends React.Component {
   imagesMobile = [slider3mobile, slider1mobile, slider2mobile,  slider4mobile, slider5mobile]
   imagesIpad = [slider3, slider1, slider2,  slider4, slider5]

   constructor(props) {
        super(props);

        this.state = {
    currentIndex: 0,
  }

      }






     galleryItems() {
       if(this.props.height / this.props.width < 1){
         return this.imagesIpad.map((photo, i) => <div  align="middle" key={i} style={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'top center', backgroundImage: 'url(' + photo + ')', height: this.props.height - 111, width: '100%', objectFit: 'cover'}}/>)
       } else {
        return this.imagesMobile.map((photo, i) => <div key={i} style={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'top center', backgroundImage: 'url(' + photo + ')', height: this.props.height - 111, width: '100%', objectFit: 'cover'}}/>)
      }
    }


             slideNext = () => {
                 this.setState((state) => ({
                     currentIndex: state.currentIndex + 1,
                 }));
             };

             slidePrev = () => {
                 this.setState((state) => ({
                     currentIndex: state.currentIndex - 1,
                 }));
             };


             updateCurrentSlide = (index) => {
                const { currentIndex } = this.state;

                if (currentIndex !== index) {
                    this.setState({
                        currentIndex: index,
                    });
                }
            };
  render() {
    const { classes, theme } = this.props;
    const { galleryItems, responsive, currentIndex } = this.state

    return (
      <div style={{position: 'relative'}}>
      <div style={{position: 'absolute', left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', bottom: 20, zIndex: 1}}>
      <div style={{textAlign: 'center'}}>
      <IconButton style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}} onClick={() => window.scrollTo({top: window.innerHeight - 111, left: 0, behavior: 'smooth'})}>
      <ArrowDown style={{color: '#ffffff'}}/>
      </IconButton>
      </div>
      </div>
      <Carousel
      showThumbs={false}
      showIndicators={false}
      showStatus={false}
      showArrows={false}
      infiniteLoop={true}
      autoPlay={false}
      interval={4000}
      transitionTime={1000}
      selectedItem={currentIndex}
      onChange={this.updateCurrentSlide}
      >
     {this.galleryItems()}
</Carousel>


      </div>
    );
  }
}

export default (withStyles(styles, { withTheme: true })(CustomCarousel))
