import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import ArrowDown from "@material-ui/icons/KeyboardArrowDown";
import logo from "../assets/logo.svg";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import Phone from "@material-ui/icons/Phone";
import { history } from "./history.js";
import * as ROUTES from "../constants/routes";
import ButtonBase from "@material-ui/core/ButtonBase";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme) => ({
  logo: {
    "&:hover": {
      opacity: 0.8,
    },
  },

  toolbar: {
    marginTop: 20,
    minHeight: 130,
    alignItems: "flex-start",
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
  },

  root: {
    flexGrow: 1,
  },
}));

export default function ProminentAppBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "#ffffff",
          boxShadow: "none",
          position: "relative",
        }}
      >
        <Toolbar className={classes.toolbar}>
          <ButtonBase
            focusRipple
            onClick={() => history.push(ROUTES.HOME)}
            style={{ position: "absolute" }}
          >
            <img
              className={classes.logo}
              src={logo}
              style={{ height: 120 }}
              alt="logo"
            />
          </ButtonBase>
          <div style={{ textAlign: "end", position: "absolute", right: 0 }}>
            <ButtonBase
              href="tel:060242220"
              style={{
                fontSize: "20px",
                margin: "auto",
                display: "inline-flex",
                color: "#ffffff",
                borderRadius: "25px",
                padding: "10px 15px 10px 15px",
                backgroundColor: "#8e4232",
              }}
            >
              <Phone
                style={{
                  marginRight: "0.5vw",
                  height: "20px",
                  width: "20px",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              />
              06024 / 2220
            </ButtonBase>
            <div
              style={{
                color: "#333",
                marginTop: "20px",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              <ButtonBase
                component="button"
                href="mailto:info@zahnarztpraxis-link.de"
              >
                info@zahnarztpraxis-link.de
              </ButtonBase>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
