import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import ProminentAppBar from './appbar.js'
import ProminentAppBarMobile from './appbarMobile.js'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Footer from './footer.js';
import FooterMobile from './footerMobile.js';

import * as ROUTES from '../constants/routes';
import {history} from './history.js';
import Navigation from './navigation.js';

import HomePage from '../pages/Home/Desktop/index.js';
import HomePageMobile from '../pages/Home/Mobile/index.js';

import Leistung1 from '../pages/Leistungen/Leistung1/Desktop/index.js';
import Leistung1Mobile from '../pages/Leistungen/Leistung1/Mobile/index.js';
import Leistung2 from '../pages/Leistungen/Leistung2/Desktop/index.js';
import Leistung2Mobile from '../pages/Leistungen/Leistung2/Mobile/index.js';
import Leistung3 from '../pages/Leistungen/Leistung3/Desktop/index.js';
import Leistung3Mobile from '../pages/Leistungen/Leistung3/Mobile/index.js';
import Infos from '../pages/Infos/Desktop/index.js';
import InfosMobile from '../pages/Infos/Mobile/index.js';


import Kontakt from '../pages/Kontakt/Desktop/index.js';
import KontaktMobile from '../pages/Kontakt/Mobile/index.js';

import Impressum from '../pages/Impressum/Desktop/index.js';
import ImpressumMobile from '../pages/Impressum/Mobile/index.js';

import Datenschutz from '../pages/Datenschutz/Desktop/index.js';
import DatenschutzMobile from '../pages/Datenschutz/Mobile/index.js';

import Team from '../pages/Team/Desktop/index.js';
import TeamMobile from '../pages/Team/Mobile/index.js';

import Bewerbungen from '../pages/Bewerbungen/Desktop/index.js';
import BewerbungenMobile from '../pages/Bewerbungen/Mobile/index.js';


import ScrollToTop from './scrollup.js'
import { isMobile } from 'react-device-detect';

import {styles} from '../breakpoints';

import { connect} from 'react-redux';
import { checkSliderpage } from "../reducers/slideScreen";
import CookieNotification from '../components/cookieslider.js'
import CoronaDialog from '../components/coronadialog.js'
import CoronaDialogMobile from '../components/coronadialogmobile.js'
import {Helmet} from "react-helmet";


class App extends Component {

  constructor(props) {
    super(props);

    this.state = {


      coronaDialog: true
    };

  }

   handleCloseDialog = () => {
  this.setState({coronaDialog: false})
     };

  render() {
    const { classes } = this.props;

    return (
        <div>
        <Helmet>
      <title>Zahnarztpraxis Link in Krombach</title>
     <meta name="description" content="Herzlich Willkommen bei den Zahnärzten Link in 63829 Krombach. Ihrer Anlaufstelle, wenn es um kompetente Beratung, Behandlung und Zahnheilkunde geht. Vereinbaren Sie noch heute einen Termin oder besuchen Sie uns direkt vor Ort." />
   </Helmet>
      <Router history={history} >

      <ScrollToTop>

      <div style={{
minHeight: '100vh',
display: "flex",
flexDirection: "column"
        }}>
        {isMobile ?

          <CoronaDialogMobile onClose={this.handleCloseDialog} open={this.state.coronaDialog}/>


          :

        <CoronaDialog onClose={this.handleCloseDialog} open={this.state.coronaDialog}/>
      }

        {this.props.showSlider &&
          <CookieNotification db={this.db} history={history}/>
        }
        {isMobile ?

          <ProminentAppBarMobile />
            :
        <div className={classes.responsive}>

      <ProminentAppBar/>

        </div>
      }
      {!isMobile &&



        <Navigation/>
      }
          <Route
            exact
            path={ROUTES.HOME}
            component={isMobile ? HomePageMobile : HomePage}
          />
          <Route
            exact
            path={ROUTES.INFOS}
            component={isMobile ? InfosMobile : Infos}
          />

          <Route
            exact
            path={ROUTES.TEAM}
            component={isMobile ? TeamMobile : Team}

          />
             <Route
            exact
            path={ROUTES.BEWERBUNGEN}
            component={isMobile ? BewerbungenMobile : Bewerbungen}

          />
          <Route
            exact
            path={ROUTES.KONTAKT}
            component={isMobile ? KontaktMobile : Kontakt}
          />
          <Route
            exact
            path={ROUTES.IMPRESSUM}
            component={isMobile ? ImpressumMobile : Impressum}
          />
          <Route
            exact
            path={ROUTES.DATENSCHUTZ}
            component={isMobile ? DatenschutzMobile : Datenschutz}
          />
          {isMobile ?

            <FooterMobile history={history} />
:
          <Footer history={history} />
        }
        </div>
        </ScrollToTop>

      </Router>
      </div>
    );
  }
}

const mapStateToProps = state => ({

  showSlider: checkSliderpage(state).show

});


export default connect( mapStateToProps )(withStyles(styles)(App));
