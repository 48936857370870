import * as types from '../constants/ActionTypes';



const setSliderPageUnsafe = (value) => ({
  type: types.CHECK_SLIDERPAGE,
value
});

export const setSliderPage = (value) => (dispatch, getState) => {
  dispatch(setSliderPageUnsafe(value));
};


const setCoronaDialogUnsafe = (value) => ({
  type: types.CHECK_SLIDERPAGE,
value
});

export const setCoronaDialog = (value) => (dispatch, getState) => {
  dispatch(setCoronaDialogUnsafe(value));
};
