import {
  CHECK_CORONADIALOG
} from '../constants/ActionTypes'

const initialState = {
  show: true,
  }

 const coronaDialog = (state = initialState, action) => {

  switch (action.type) {
    case CHECK_CORONADIALOG:
    return {
      ...state,
      show: false,

  }
    default:
      return state
  }
}




export const checkCoronaDialog = state => state.coronaDialog

export default coronaDialog;
