export const styles = theme => ({

  root: {
     "&::before":{
      height: 0
    },
    margin: "0px !important"
  },

  responsive: {

    [theme.breakpoints.down('sm')]: {
      width: '84%',
      marginLeft: 'auto',
      marginRight: 'auto'

    },
    [theme.breakpoints.up('md')]: {
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto'

    },
    [theme.breakpoints.up('lg')]: {
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto'

    },
    [theme.breakpoints.up('xl')]: {
        width: '60%',
        marginLeft: 'auto',
        marginRight: 'auto'
          },

  },

  flowHeadline: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: '#13294b', hyphens: 'auto',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {

      fontSize: '1.7rem',



    },
    [theme.breakpoints.up('md')]: {

      fontSize: '1.7rem',



    },
    [theme.breakpoints.up('lg')]: {

      fontSize: '2rem',



    },
    [theme.breakpoints.up('xl')]: {

      fontSize: '2rem',

          },

  },

  flowSubHeadline: {
    [theme.breakpoints.down('sm')]: {
      color: '#13294b', hyphens: 'auto',
      textAlign: 'left',
      fontSize: '1.4rem',
      fontWeight: 'bold'
    },
    [theme.breakpoints.up('md')]: {
      color: '#13294b', hyphens: 'auto',
      textAlign: 'left',
      fontSize: '1.4rem',
      fontWeight: 'bold'

    },
    [theme.breakpoints.up('lg')]: {
      color: '#13294b', hyphens: 'auto',
      textAlign: 'left',
      fontSize: '1.6rem',
      fontWeight: 'bold'

    },
    [theme.breakpoints.up('xl')]: {
      color: '#13294b', hyphens: 'auto',
      textAlign: 'left',
      fontSize: '1.6rem',
      fontWeight: 'bold'

          },

  },

  flowText: {
    textAlign: 'justify',
    wordBreak: 'break-word',
    hyphens: 'auto',

    [theme.breakpoints.down('sm')]: {
      color: '#13294b',
     
      fontSize: '1rem'

    },
    [theme.breakpoints.up('md')]: {
      color: '#13294b', hyphens: 'auto',
      fontSize: '1rem'

    },
    [theme.breakpoints.up('lg')]: {
      color: '#13294b', hyphens: 'auto',
      fontSize: '1.2rem'

    },
    [theme.breakpoints.up('xl')]: {
      color: '#13294b', hyphens: 'auto',
      fontSize: '1.2rem'
          },

  },


  headlines: {
    [theme.breakpoints.down('sm')]: {
        color: '#13294b',
    fontWeight: 'bold',
    textAlign: 'start !important',
      fontSize: '1.8rem'

    },
    [theme.breakpoints.up('md')]: {
      color: '#13294b',
  fontWeight: 'bold',
  textAlign: 'start !important',
  fontSize: '1.8rem'

    },
    [theme.breakpoints.up('lg')]: {
      color: '#13294b',
  fontWeight: 'bold',
  textAlign: 'start !important',
  fontSize: '2rem'

    },
    [theme.breakpoints.up('xl')]: {
      color: '#13294b',
  fontWeight: 'bold',
  textAlign: 'start !important',
  fontSize: '2rem'
          },

  },

  formControlMobile: {
    width: '100%',
    minWidth: 120,
    '& .MuiOutlinedInput-root': {

      '&:hover fieldset': {
        borderColor: '#8e4232',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8e4232',
      },
    },
        borderColor: '#8e4232',



  },

  formControl: {
    width: '45%',
    minWidth: 120,
    '& .MuiOutlinedInput-root': {

      '&:hover fieldset': {
        borderColor: '#8e4232',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8e4232',
      },
    },
        borderColor: '#8e4232',



  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

   label: {
          '&.MuiFormLabel-root.Mui-focused':{
            color: '#8e4232',
          }
      },

      button: {
        display: 'flex',
        marginLeft: 'auto',
        marginTop: '4vh',
          backgroundColor: '#13294b',
           color: '#ffffff',
    "&:disabled": {
      backgroundColor: "grey"
    },
    "&:hover":{
      backgroundColor: '#13294b',
      opacity: 0.8
    }
}



});
