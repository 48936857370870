import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import "../../../App.css";
import Divider from "@material-ui/core/Divider";
import * as ROUTES from "../../../constants/routes";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { styles } from "../../../breakpoints";

class Bewerbungen extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div style={{ marginBottom: "6vh" }}>
        <Helmet>
          <title>Bewerbungen</title>
          <meta name="description" content="Unser Impressum" />
        </Helmet>
        <div style={{ textAlign: "center" }}>
          <div className={classes.responsive}>
            <div style={{ marginTop: "2vh" }}>
              <div style={{ width: "100%" }}>
                <p className={classes.headlines}>Bewerbungen</p>
                <br />
                <p className={classes.flowText}>
                  Wir freuen uns über Initiativbewerbungen und Bewerbungen zur
                  Ausbildung zur Zahnmedizinische/r Fachangestellte/r (m,w,d).
                </p>
                <br />
                <p className={classes.flowText}>
                  Schicken Sie gerne Ihre Unterlagen per Post an:
                  <br />
                  <br />
                  Zahnarztpraxis Link
                  <br />
                  Ullastr. 3<br />
                  63829 Krombach
                  <br />
                </p>
                <br />
                <p className={classes.flowText}>
                  Oder per Email an: <br />
                  <a
                    href="mailto:bewerbung@zahnarztpraxis-link.de"
                    style={{
                      color: "#13294b",
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    bewerbung@zahnarztpraxis-link.de{" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Bewerbungen);
