import React, { PureComponent, useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { connect, useDispatch } from "react-redux";
import { CHECK_CORONADIALOG } from "../constants/ActionTypes";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import blzk from "../assets/blzk.png";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      zIndex: "1500 !important",
    },
    paper: {
      width: "100%",
      overflow: "hidden",
      margin: 16,
    },
  })
);

export default function CoronaDialogMobile(props) {
  const { onClose, open } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  function handleClose() {
    onClose();
  }

  function accept() {
    dispatch({ type: CHECK_CORONADIALOG });
    handleClose();
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="login-dialog-title"
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ root: classes.root, paper: classes.paper }}
    >
      <DialogContent>
        {/* <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 40}}>
      <div style={{fontWeight: 'bold'}}>Bayerische Landeszahnärztekammer
      <br/>
Kassenzahnärztliche Vereinigung Bayerns
</div>
        <img src={blzk} style={{height: 100}}/>
       </div> */}
        <div>
          <div style={{ fontSize: "2rem", fontWeight: "bold" }}>
            Neuigkeiten{" "}
          </div>
          <br />
          <div>Liebe Patientinnen und Patienten,</div>

          <br />
          <div>
            wir sind umgezogen!
            <br />
            In Zukunft finden Sie uns im Nachbarhaus:{" "}
          </div>
          <br />
          <div style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
            Ullastr. 3<br />
            63829 Krombach
          </div>
          <br />
          <div>Wir freuen uns auf Ihren Besuch.</div>
        </div>
      </DialogContent>
      <DialogActions style={{ padding: 30 }}>
        <Button
          variant="contained"
          onClick={accept}
          style={{ color: "#ffffff", backgroundColor: "#13294b" }}
        >
          Verstanden
        </Button>
      </DialogActions>
    </Dialog>
  );
}
