import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import "../../../App.css";
import Divider from '@material-ui/core/Divider';
import * as ROUTES from '../../../constants/routes';
import {
  Link
} from "react-router-dom";
import {Helmet} from "react-helmet";

import {styles} from '../../../breakpoints';



class Impressum extends Component {





  render(){

const { classes } = this.props;

    return(
      <div style={{marginBottom: '4vh'}}>
      <Helmet>
    <title>Impressum</title>
   <meta name="description" content="Unser Impressum" />
 </Helmet>
        <div style={{textAlign: 'center'}}>

          <div className={classes.responsive}>
          <div style={{  marginTop: '2vh'}}>

            <div style={{width: '100%'}}>
            <p className={classes.headlines}>Impressum</p>
            <br/>
            <p className={classes.flowText}>
            Berufsausübungsgemeinschaft
            <br/>
            <br/>
    Zahnärzte
    <br/>
    
      <span style={{fontWeight: 'bold'}}>Dr.med.dent. Katharina Link-Löffler</span>
      <br/>
      <span style={{fontWeight: 'bold'}}>Dr.med.dent. Paulina Link</span>
      <br/>
      <span style={{fontWeight: 'bold'}}>Dr.med.dent. Sebastian Link</span>
        <br/>
        <span style={{fontWeight: 'bold'}}>Michael Link</span>
      <br/>
      Ullastr. 3
        <br/>
        63829 Krombach
        <br/>
       
        <br/>
        Tel.:  <a  href="tel:060242220" style={{color: '#13294b', textDecoration: 'none'}}>  06024 / 22 20 </a>

        <br/>
        E-Mail:  <a         href="mailto:info@zahnarztpraxis-link.de" style={{color: '#13294b', textDecoration: 'none'}}>   info@zahnarztpraxis-link.de </a>
        </p>
<br/>
<br/>
<Divider />
<br/>
    <p style={{fontWeight: 'bold', textAlign: 'left', fontSize: '1.2rem'}}> Datenschutzbeauftragte: </p>
    <br/>
    <p className={classes.flowText}>

  Anna-Lena Mehling
    <br/>
    <br/>
    Tel.:  <a  href="tel:060242220" style={{color: '#13294b', textDecoration: 'none'}}>  06024 / 22 20 </a>

    <br/>
    E-Mail:  <a href="mailto:datenschutz@zahnarztpraxis-link.de" style={{color: '#13294b', textDecoration: 'none'}}>    datenschutz@zahnarztpraxis-link.de </a>
    <br/>
    </p>
<br/>
<Divider />
<br/>
<p style={{fontWeight: 'bold', textAlign: 'left', fontSize: '1.2rem'}}> Verwantwortlich für sämtliche Inhalte: </p>
<br/>
<p className={classes.flowText}>

Michael Link
<br/>
<br/>
Tel.:  <a  href="tel:060242220" style={{color: '#13294b', textDecoration: 'none'}}>  06024 / 22 20 </a>

<br/>
E-Mail:  <a href="mailto:info@zahnarztpraxis-link.de" style={{color: '#13294b', textDecoration: 'none'}}>   info@zahnarztpraxis-link.de </a>
<br/>
</p>
<br/>
<Divider />
<br/>
<p style={{fontWeight: 'bold', textAlign: 'left', fontSize: '1.2rem'}}> Fotografen und Fotografinnen: </p>
<br/>
<p className={classes.flowText}>

Michaela Ulrich Fotografie
<br/>
Somborner Str. 24
<br/>
63579 Freigericht
<br/>
<br/>

Tel.: <a  href="tel:060242220" style={{color: '#13294b', textDecoration: 'none'}}>06055 – 909645</a>
<br/>
Website: <a target="_blank" rel="noopener noreferrer" href="https://www.michaela-fotografie.de" style={{color: '#13294b', textDecoration: 'none'}}>www.michaela-fotografie.de</a>
<br/>
E-Mail: <a href="mailto:info@michaelafotografie.de" style={{color: '#13294b', textDecoration: 'none'}}>info@michaelafotografie.de</a>

<br/>
</p>
<br/>
<br/>

<p className={classes.flowText}>

Juliana Füssel 
<br/>
forTINA Photography 
<br/>
Schneidmühlweg 103
<br/>
63741 Aschaffenburg

<br/>
</p>
<br/>
<br/>

<p className={classes.flowText}>

Atelier 4.0
<br/>
Schimborner Straße 22
<br/>
63776 Mömbris
<br/>
<br/>

Tel.: <a  href="tel:01752372511" style={{color: '#13294b', textDecoration: 'none'}}>0175 237 2511</a>
<br/>
Website: <a target="_blank" rel="noopener noreferrer" href="https://www.fortina-photography.de/" style={{color: '#13294b', textDecoration: 'none'}}>www.fortina-photography.de</a>
<br/>
E-Mail: <a href="mailto:fortina.photography@gmail.com" style={{color: '#13294b', textDecoration: 'none'}}>fortina.photography@gmail.com</a>

<br/>
</p>
<br/>

<Divider />
<br/>
<p style={{fontWeight: 'bold', textAlign: 'left', fontSize: '1.2rem'}}> Zuständige Kammer: </p>
<br/>
<p className={classes.flowText}>

Bayerische Landeszahnärztekammer
<br/>

Flößergasse 1
<br/>
81369 München
<br/>
<br/>

Tel.: <a  href="tel:089 72480-0" style={{color: '#13294b', textDecoration: 'none'}}>089 72480-0</a>
<br/>
Fax: 089 72480-444
<br/>
Website: <a target="_blank" rel="noopener noreferrer" href="https://www.blzk.de/" style={{color: '#13294b', textDecoration: 'none'}}>www.blzk.de</a>

<br/>
E-Mail: <a href="mailto:blzk@blzk.de" style={{color: '#13294b', textDecoration: 'none'}}>blzk@blzk.de</a>

<br/>
</p>
<br/>
<Divider />
<br/>
<p style={{fontWeight: 'bold', textAlign: 'left', fontSize: '1.2rem'}}> Berufsrechtliche Regelungen: </p>
<br/>
<p className={classes.flowText}>
+ Zahnheilkundegesetz
<br/>
+ Berufsordnung für die bayerischen Zahnärzte
<br/>
+ Heilberufe-Kammergesetz
<br/>
+ Gebührenordnung für Zahnärzte
<br/>
diese sind einsehbar auf <a target="_blank" rel="noopener noreferrer" href="https://www.blzk.de/" style={{color: '#13294b', textDecoration: 'none'}}>www.blzk.de</a> unter Menüpunkt „Recht“

<br/>
</p>
<br/>
<Divider />
<br/>
<p style={{fontWeight: 'bold', textAlign: 'left', fontSize: '1.2rem'}}> Vertragszahnärztliche Tätigkeit: </p>
<br/>
<p className={classes.flowText}>

ABE-Nr. 018811
<br/>
<br/>
Kassenzahnärztliche Vereinigung Bayern
<br/>
Fallstraße 34
<br/>
81369 München
<br/>

Tel.: <a  href="tel:089 72401-0" style={{color: '#13294b', textDecoration: 'none'}}>089 72401-0</a>
<br/>
Fax: 089 72401-291
<br/>
Website: <a target="_blank" rel="noopener noreferrer" href="https://www.kzvb.de/" style={{color: '#13294b', textDecoration: 'none'}}>www.kzvb.de</a>

<br/>
</p>
<br/>
<Divider />
<br/>
<p style={{fontWeight: 'bold', textAlign: 'left', fontSize: '1.2rem'}}>Aufsichtsbehörde: </p>
<br/>
<p className={classes.flowText}>

Regierung von Unterfranken

<br/>
Peterplatz 9
<br/>
97070 Würzburg
<br/>

Tel.: <a  href="tel:0931 380-00" style={{color: '#13294b', textDecoration: 'none'}}>0931 380-00</a>
<br/>
Fax: 0931 380-2222
<br/>
Website: <a target="_blank" rel="noopener noreferrer" href="https://regierung.unterfranken.bayern.de" style={{color: '#13294b', textDecoration: 'none'}}>www.kzvb.de</a>

<br/>
</p>
<br/>
<Divider />
<br/>
<p style={{fontWeight: 'bold', textAlign: 'left', fontSize: '1.2rem'}}>Aufsichtsbehörde für den Datenschutz: </p>
<br/>
<p className={classes.flowText}>

Bayerisches Landesamt für Datenschutz (ByLDA)

<br/>
Promenade 27
<br/>
91522 Ansbach
<br/>

Tel.: <a  href="tel:0981 180093-0" style={{color: '#13294b', textDecoration: 'none'}}>0981 180093-0</a>
<br/>
Fax: 0981 180093-800
<br/>
Website: <a target="_blank" rel="noopener noreferrer" href="http://www.lda.bayern.de/" style={{color: '#13294b', textDecoration: 'none'}}>www.lda.bayern.de</a>

<br/>
E-Mail: <a href="mailto:poststelle@lda.bayern.de" style={{color: '#13294b', textDecoration: 'none'}}>poststelle@lda.bayern.de</a>
</p>
<br/>
<Divider />
<br/>
<p style={{fontWeight: 'bold', textAlign: 'left', fontSize: '1.2rem'}}>Haftungshinweis:</p>
<br/>
<p className={classes.flowText}>

Alle auf dieser Internetseite bereitgestellten Informationen haben wir nach bestem Wissen
und Gewissen erarbeitet und geprüft. Eine Gewähr für die jederzeitige Aktualität, Richtigkeit,
Vollständigkeit und Verfügbarkeit der bereit gestellten Informationen können wir nicht
übernehmen. Ein Vertragsverhältnis mit den Nutzern des Internetangebotes kommt nicht
zustande.
<br/>
Für Schäden, die durch Nutzung dieses Internetangebots entstehen, haften wir nicht. Für
etwaige Schäden, die beim Aufrufen oder Herunterladen von Daten durch Schadsoftware
oder der Installation oder Nutzung von Software verursacht werden, wird nicht gehaftet.
<br/>
<br/>
Wir distanzieren uns ausdrücklich von allen Inhalten aller von unserer Internetpräsenz
verlinkten Seiten und machen uns alle diese Inhalte nicht zu Eigen.
</p>
<br/>
<Divider />
<br/>
<p style={{fontWeight: 'bold', textAlign: 'left', fontSize: '1.2rem'}}>Datenschutzhinweis:</p>
<br/>
<p className={classes.flowText}>
Der Nutzung unserer veröffentlichten Kontaktdaten durch Dritte, um z.B. nicht ausdrücklich
angeforderte Werbung, Informationsmaterialien oder Proben zu übersenden, widersprechen
wir hiermit ausrücklich.
<br/>
<br/>
Weitere Informationen zu unserem Datenschutz gibt es <Link to={ROUTES.DATENSCHUTZ}>hier</Link>.
</p>
<br/>
<Divider />
<br/>
<p style={{fontWeight: 'bold', textAlign: 'left', fontSize: '1.2rem'}}>Bildrechte:</p>

  <br/>
  <p className={classes.flowText} style={{marginBottom: 20}}>
    Die Nutzungsrechte aller verwendeten Fotos und Abbildungen liegen bei der überörtlichen Berufsausübungsgemeinschaft Zahnärzte Michael Link und Heinz-Georg Schneider und dürfen nicht von Dritten ohne Einwilligung verwendet werden.

    <br/>
    <br/>
    <span style={{fontWeight: 'bold'}}>Ausnahme: </span> Das Bildrecht der Abbilung der Schnarchschiene unter dem Menüpunkt "Infos" (<a target="_blank" rel="noopener noreferrer" href="https://zahnarztpraxis-link.de/infos">zahnarztpraxis-link.de/infos</a>) liegt bei <span style={{fontWeight: 'bold'}}>SCHEU-DENTAL GmbH</span>.

  </p>
</div>
                      </div>
          </div>

            </div>

        </div>
      );

  }
}




export default (withStyles(styles, { withTheme: true })(Impressum));
