import React from 'react';

import { setSliderPage } from '../actions';
import { connect, useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import * as ROUTES from '../constants/routes';

import { CHECK_SLIDERPAGE } from '../constants/ActionTypes';

export const CookieNotification = props => {
  const dispatch = useDispatch();


  function onComplete(){
    localStorage.setItem('acceptedCookies', true);

    dispatch({ type: CHECK_SLIDERPAGE });



  }


  function pageContentMobile() {
    if(!localStorage.getItem('acceptedCookies')){

        return (
          <div
              style={{
                width: '100vw',
              backgroundColor: 'rgb(142, 66, 50)',
                position: 'fixed',
                bottom: 0,
                zIndex: 5000,
              }}
            >
              <div

              >
              <div style={{ color: '#ffffff', margin: "30px 30px 20px 30px" }}>
                  Diese Webseite verwendet Cookies, um bestimmte
                  Funktionen zu ermöglichen und das Angebot zu
                  verbessern. Indem Sie hier fortfahren, stimmen Sie
                  der Nutzung von Cookies zu.
                    {' '}
                    <Link
                      component="button" style={{color: '#ffffff', fontWeight: 'bold', textDecoration: 'underline white'}}    onClick={() => props.history.push(ROUTES.DATENSCHUTZ)}>
                    Mehr Informationen
                    </Link>
                </div>
                <div style={{margin: "0px 30px 30px 30px" }}>
                  <Button
                    variant="outlined"
                      fullWidth
                    style={{
                      borderColor: '#ffffff',
                      color: 'rgba(255, 255, 255, 1)',
                      fontSize: 12
                                        }}
                    onClick={() => {
                      onComplete();
                    }}
                  >
                    Ich stimme zu
                  </Button>
                </div>
              </div>
            </div>
        );
      }

}


  function pageContentDesktop() {
    if(!localStorage.getItem('acceptedCookies')){


        return (
          <div
              style={{
                width: '100vw',
                backgroundColor: 'rgb(142, 66, 50)',

                position: 'fixed',
                bottom: 0,
                zIndex: 5000,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ color: '#ffffff', margin: 30 }}>
                  Diese Webseite verwendet Cookies, um bestimmte
                  Funktionen zu ermöglichen und das Angebot zu
                  verbessern. Indem Sie hier fortfahren, stimmen Sie
                  der Nutzung von Cookies zu.
                  <br/>
                  <span style={{ fontWeight: 'bold' }}>
                    {' '}
                    <Link
                      component="button"       style={{color: '#ffffff', fontWeight: 'bold'}}    onClick={() => props.history.push(ROUTES.DATENSCHUTZ)}>
                    Mehr Informationen
                    </Link>
                  </span>
                </div>
                <div style={{ margin: 30 }}>
                  <Button
                    variant="outlined"
                    size="large"
                    style={{
                      borderColor: '#ffffff',
                      color: 'rgba(255, 255, 255, 1)',
                      fontSize: 12,
                      width: 'max-content',
                    }}
                    onClick={() => {
                      onComplete();
                    }}
                  >
                    Ich stimme zu
                  </Button>
                </div>
              </div>
            </div>
        );
      }

}





  if(isMobile){

  return (


  <div>
{pageContentMobile()}
  </div>
)
  } else {

    return(
      <div>
      {pageContentDesktop()}
      </div>
    )


  }






};

const mapStateToProps = (state) => ({
  sliderPage: state,
});

export default connect(
  mapStateToProps,
  { setSliderPage },
)(CookieNotification);
