import React, { Component } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import "../../../../App.css";
import { motion } from "framer-motion";


import {styles} from '../../../../breakpoints';



class Leistung3 extends Component {


  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 0,
    windowHeight: 0
    }
    this.updateDimensions = this.updateDimensions.bind(this);

  }


  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

    this.setState({ windowWidth, windowHeight });
  }



  render(){
    const {
windowWidth,
windowHeight
} = this.state;
const { classes, theme } = this.props;

    return(
      <div style={{marginBottom: '4vh'}}>

        <div style={{textAlign: 'center'}}>

          <div className={classes.responsive}>
          <div style={{  marginTop: '2vh'}}>

            <div style={{width: '100%'}}>
            <p className={classes.headlines}>Leistung 3</p>
            <br/>
            <p className={classes.flowText}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
             sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
              Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
              et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
              no sea takimata sanctus est Lorem ipsum dolor sit amet.
<br/>
<br/>

<span style={{fontWeight: 'bold'}}> Lorem Ipsum Lorem Ipsum </span>
<br/>
<br/>

              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
              tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
              no sea takimata sanctus est Lorem ipsum dolor sit amet.



                              <br/>

                              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                              tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                              no sea takimata sanctus est Lorem ipsum dolor sit amet.
                              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                              tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                              no sea takimata sanctus est Lorem ipsum dolor sit amet.  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                                tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
                                no sea takimata sanctus est Lorem ipsum dolor sit amet.

                      </p>
                      </div>
          </div>

            </div>

        </div>
      </div>
      );

  }
}




export default (withStyles(styles, { withTheme: true })(Leistung3));
